import { datadogRum } from '@datadog/browser-rum';
import { VERSION } from '../version';

export function initializeDatadogRum() {
  const datadogRumAppId = window.ambulnzConfig.constants.DATADOG_REQUESTER_RUM_APP_ID;
  const datadogRumClientToken = window.ambulnzConfig.constants.DATADOG_REQUESTER_RUM_CLIENT_TOKEN;
  const datadogRumEnv = window.ambulnzConfig.constants.DATADOG_RUM_ENV;
  const datadogRumSampleRate = Number(window.ambulnzConfig.constants.DATADOG_REQUESTER_RUM_SAMPLE_RATE);
  const datadogRumSessionSampleRate = Number(window.ambulnzConfig.constants.DATADOG_REQUESTER_RUM_SESSION_SAMPLE_RATE);
  datadogRum.init({
    applicationId: datadogRumAppId,
    clientToken: datadogRumClientToken,
    site: 'datadoghq.com',
    service: 'requester',
    env: datadogRumEnv,
    version: VERSION,
    sessionSampleRate: datadogRumSampleRate,
    sessionReplaySampleRate: datadogRumSessionSampleRate,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: ['all'],
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: ["/https:\/\/.*\.ambulnz-dev\.net/", "/https:\/\/.*\.ambulnz\.net/", "/https:\/\/.*\.ca1\.proda6-dev\.net/", "/https:\/\/.*\.us1\.proda6\.net/"],
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
  });
}
